import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import VerificationForm from '../components/VerificationForm';
import { useState, useEffect } from 'react';
import BackGround from '../components/BackGround';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function VerificationPage() {
  const navigate = useNavigate();
  const query = useQuery();
  const userId = query.get('userId');
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const sessionId = localStorage.getItem('sessionId');
  const [lastClick, setLastClick] = useState(Date.now());
  const [countdown, setCountdown] = useState(40);

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = 40 - Math.floor((Date.now() - lastClick) / 1000);
      setCountdown(timeLeft > 0 ? timeLeft : 0);
    }, 1000);
    return () => clearInterval(interval);
  }, [lastClick]);

  const handleVerification = async (userId, code) => {
    setLoading(true);
    try {
      const response = await fetch(`api/auth/verify/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'An error occurred during registration');

      const dob = new Date(data.dob);
      const age = new Date().getFullYear() - dob.getFullYear();


      if (age < 18) {
        if (data.ecd === false) {
          navigate(`/parentlink?userId=${userId}`);
        } else {
          navigate(`/login`);
        }
      } else {
        if (data.ecd === false) {
          localStorage.setItem('session', JSON.stringify(data.sessionId));
          navigate(`/add-emergancy-contact`);
        } else {
          navigate(`/login`);
        }
      }
    } catch (error) {
      console.error("Verification error:", error);
      setError(error.message);
      setLoading(false);
    }
  };
  const resendVerification = () => {
    if (Date.now() - lastClick < 40000) {
      console.log('Wait a bit before resending verification');
      return;
    }
    try {
      fetch(`api/auth/resend-verification-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId }),
      });
    } catch (error) {
      console.error("Resend Verification error:", error);
      setError(error.message);
      setLoading(false);
    }
    setLastClick(Date.now());
  };
  

  return (

    <div>
      <BackGround />
      <h2>Verify Your Account</h2>
      <VerificationForm onSubmitVerification={handleVerification} userId={userId} ></VerificationForm>
      <button style={{marginTop: "20px", fontSize: "13px", backgroundColor: countdown > 0 ? 'rgb(53 0 61)' : ''}} onClick={resendVerification} disabled={countdown > 0}>Resend Verification Code {countdown > 0 && `(${countdown}s)`}</button>
      {error && <p className='error'>{error}</p>}
      {loading && <div className="overlay"></div>}
      {loading && <div className="spin"></div>}
    </div>
  );
}

export default VerificationPage;