import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackGround from "../components/BackGround";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function EmailVerify() {
    const navigate = useNavigate();
    const query = useQuery();
    const userId = query.get("userId");
    const [email, setEmail] = useState(''); 
    const [error, setError] = useState(''); 
    const [isLoading, setLoading] = useState(false); 

    const handleAddressSuccess = (userId) => {
        navigate(`/email-verify/add-password?userId=${userId}`);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); 

        const emailData = JSON.stringify({ email }); 
        setLoading(true); 

        try {
            const response = await fetch(`api/auth/verify-email/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: emailData,
            });
            const data = await response.json();
            if (!response.ok) {
                setError(data.message);
                throw new Error(data.message || 'Email verification failed');
            }
            console.log('Registration successful', email);
            handleAddressSuccess(userId);
            localStorage.setItem('sessionId', data.sessionId)
        } catch (error) {
            console.error("Address submission error:", error);
            setError(error.message);
        }
    };

    useEffect(() => {
        document.title = "Email Verification";
    }, []);

    return (
        <div>
            <BackGround />
            <h3>Email Verification</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" onChange={e => setEmail(e.target.value)} className="form-control" placeholder="Enter email" required />
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                    Verify
                </button>
                {error && <div className="error">{error}</div>}
            </form>

            {isLoading && <div className="overlay"></div>}
            {isLoading && <div className="spin"></div>}
        </div>
    );
}


export default EmailVerify;

