import { data } from "jquery";
import React, { useState, useRef } from "react";
import BackGround from "../components/BackGround";

function ResetPassword() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [succsess, setSuccess] = useState(false);
    const emailRef = useRef();

    async function handlePasswordReset(e) {
        e.preventDefault();
        setLoading(true);
        setEmail(emailRef.current.value);
        try {
            const response = await fetch("api/auth/send-new-password-email", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });
            data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || "An error occurred");
            }
            console.log("Password reset email sent");
            setError(null);
            setSuccess(true);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <BackGround />
            <h1>Reset Password</h1>
            <form onSubmit={handlePasswordReset} >
                <label>
                    <input placeholder="Email" type="email" name="email" ref={emailRef} />
                </label>
                <button type="submit" >Reset Password</button>
                {succsess && <p className='success'>Password reset email was sent to {email}</p>}
                {error && <p className='error'>{error}</p>}
            </form>
            {loading && <div className="overlay"></div>}
            {loading && <div className="spin"></div>}
        </div>
    );
}

export default ResetPassword;