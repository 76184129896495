import React, { useEffect, useState } from "react";
import LoginForm from "../components/LoginForm";
import { useNavigate } from "react-router-dom";
import ValidateSession from "../components/ValidateSession";
import BackGround from "../components/BackGround";

function LoginPage() {
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const sessionId = localStorage.getItem("sessionId");
    useEffect(() => {

        try {
            const validateSessionId = async () => {
                const sessionId = localStorage.getItem("sessionId");

                const response = await fetch("api/auth/validate-session", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ sessionId }),
                });
                const data = await response.json();
                if (response.ok) {
                    navigate("/dashboard");
                }
            }
            validateSessionId();
        } catch (error) {
            console.error("Verification error:", error);
            setError(error.message);
            return;
        }
    });

    const handleLogin = async (email, password) => {
        try {
            setIsLoading(true);

            const response = await fetch("api/auth/login", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem("sessionId", data.user.session_token);
                navigate("/dashboard");
                return true;
            } else if (data.message === "User is a child and not linked to a parent") {
                const userId = data.user.id;
                navigate(`/parentlink?userId=${userId}`)
                setError(error);
                setIsLoading(false);
                return { success: false, error };

            } else if (response.status === 401) {
                const error = data.message || "An error occurred";
                setError(error);
                setIsLoading(false);
                return { success: false, error };
            }

            else if (response.status === 402) {
                const userId = data.user.id;
                setIsLoading(false);
                navigate(`/payment?userId=${userId}`);
                return false;
            }
            else if (response.status === 403) {
                navigate(`/verification?userId=${data.user.id}`);
                setError("Please verify your email address before logging in.");
                setIsLoading(false);
                return { success: false, error };
            }
            else {
                const error = data.message || "An error occurred";
                setError(error);
                setIsLoading(false);
                return { success: false, error };
            }
        } catch (error) {
            console.error("Login error:", error);
            setError(error.message);
            setIsLoading(false);
            return { success: false, error };
        }
    };

    return (
        <div>
            <BackGround />
            <h2>Login</h2>
            <LoginForm onLogin={handleLogin} error={error} />
            {isLoading && <div className="overlay"></div>}
            {isLoading && <div className="spin"></div>}
        </div>
    );
}

export default LoginPage;
