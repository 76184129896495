
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BackGround from "../components/BackGround";

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
function EnterNewPassword() {
    const navigate = useNavigate();
    const query = useQuery();
    const resetCode = query.get('resetCode');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleNewPassword = async (event) => {
        event.preventDefault(); // Prevent form from reloading the page

        // Simple validation to check if passwords match
        if (newPassword !== confirmPassword) {
            console.log("Passwords do not match!");
            setError("Passwords do not match!");
            return;
        }

        try {
            setLoading(true);
            const response = await fetch("api/auth/reset-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ resetCode, newPassword }),
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || "An error occurred");
            }
            console.log("Password reset successfully");
            setError(null);
            setLoading(false);
            navigate("/login")
        } catch (error) {
            setLoading(false);
            console.error("Failed to reset password:", error);
            setError(error.message);

        }
    };

    return (
        <div>
            <BackGround />
            <h1>Enter New Password</h1>
            <form onSubmit={handleNewPassword}>
                    <input
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                <button type="submit">Submit</button>
                {error && <p className='error'>{error}</p>}
            </form>
            {loading && <div className="overlay"></div>}
            {loading && <div className="spin"></div>}
        </div>
    );
}

export default EnterNewPassword;
